import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
import { Outlet, Link } from "react-router-dom";
import Mainindex from './mainindex';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form

            userid: null,
            point: 0,
            balance: 0,
            loginstate: null,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            username: "",
            amount: "",
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            pathname: "",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            name: "",
            password: "",
            left: false,
        }
    }
    logout = (e) => {

        // window.location.href = '/logout';

    }
    async componentDidMount() {
        this.setState({
            pathname: window.location.pathname.replace("/", "").trim()
        });
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var userAgent = navigator.userAgent.toLowerCase()

        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }
        catch (error) {
            console.log(error);
        }

    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }

    cancelredeempromotion = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/usercancelpromotion", {
                        User_id: Username,
                        System: Systems,
                        no: index.no,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            this.checkpro(null, 999);
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data)
                // console.log(res.data.message.tblpromotionredeems)
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions,
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeemlist: res.data.message.tblpromotionredeems,
                    });

                }

            }
            else if (res.data.status === 201) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //  this.rechecklogin(Username, token);
        this.setState({
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            //  console.log(5);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                    var i, tablinks;
                    tablinks = document.getElementsByClassName("-btn-balance");
                    for (i = 0; i < tablinks.length; i++) {
                        tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
                    }
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    //   console.log(res.data);
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                //    console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    render() {
        const pathname = this.state.pathname
        const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist } = this.state
        // //////console.log(this.state);
        return (
            <>
                <nav className="nav-menu animated animatedFadeInUp in-left" id="navbarCategory">
                    <ul className="x-menu-provider nav nav-pills js-menu-container -nav-menu-container mt-2">
                        <li className="nav-item">
                            <button
                                className={"nav-link -casino " + (pathname === "" ? "active" : "")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = '/';
                                }}
                            >
                                <img
                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/9855c703-859c-4613-b4db-977aebad6c00/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title -title-active">
                                        <span>ยอดนิยม</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}

                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = '/casino';
                                }}
                            >
                                <img
                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/8195e083-f656-4314-8df1-331b04465c00/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>คาสิโน</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={"nav-link -casino " + (pathname === "slot" ? "active" : "")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = '/slot';
                                }}
                            >
                                <img
                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/9855c703-859c-4613-b4db-977aebad6c00/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>สล็อต</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                  className={"nav-link -casino " + (pathname === "sport" ? "active" : "")}
                                  onClick={(e) => {
                                      e.preventDefault();
                                      window.location.href = '/sport';
                                  }}
                            >
                                <img
                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/194f710e-cbf2-4d55-636b-d17e68a55200/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>กีฬา</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={"nav-link -casino " + (pathname === "fishing" ? "active" : "")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = '/fishing';
                                }}
                            >
                                <img
                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/aa0a4345-cbb5-4cef-754b-cf09f11e6400/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>ยิงปลา</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                  className={"nav-link -casino " + (pathname === "sport" ? "active" : "")}
                                //   onClick={(e) => {
                                //       e.preventDefault();
                                //       window.location.href = '/trade';
                                //   }}
                            >
                                <img
                                    src="https://imagedelivery.net/OxMo058wRPZzbWHctmHqkA/5b83a31b-93a0-4d6f-71c7-5b2b73bacb00/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>เทรดดิ้ง</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                  className={"nav-link -casino " + (pathname === "sport" ? "active" : "")}
                                //   onClick={(e) => {
                                //       e.preventDefault();
                                //       window.location.href = '/lotto';
                                //   }}
                            >
                                <img
                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2e8d1bac-60e6-476f-910b-dd3037eb3100/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>หวย</span>
                                    </div>
                                </div>
                            </button>
                        </li>
                        {/* <li className="nav-item">
                            <button
                                className="nav-link -casino"
                                data-ajax-game-load="/_ajax_/%E0%B8%84%E0%B8%B2%E0%B8%AA%E0%B8%B4%E0%B9%82%E0%B8%99"
                                data-target=".js-game-container"
                                data-href-push-state="/%E0%B8%84%E0%B8%B2%E0%B8%AA%E0%B8%B4%E0%B9%82%E0%B8%99"
                                data-menu-container=".js-menu-container"
                                data-menu-mobile-container=".js-menu-mobile-container"
                                data-category="casino"
                                data-loading="_onLoading_"
                            >
                                <img
                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7433d2df-f81a-4acc-19a2-12f9ebeebc00/public"
                                    alt="img webคาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                    className="img-fluid -ic-menu"
                                    width={55}
                                    height={55}
                                />
                                <div className="-text-provider-wrapper pl-1-mobile">
                                    <div className="-text-nav-menu -title">
                                        <span>หวย</span>
                                    </div>
                                </div>
                            </button>
                        </li> */}
                    </ul>
                    <div className="-contact-wrapper m-4">
                        <div className="x-contact-us -text">
                            <a role="button"   href={this.state.linelink} className="-line-wrapper" rel="noopener noreferrer">
                                <img
                                    role="button"
                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                    className="-line-img"
                                    width={160}
                                    height={51}
                                />
                            </a>
                        </div>
                    </div>
                </nav>

                <div
                    className="x-modal modal -v2 show"
                    id="joinPromotionModalMobile"
                    tabIndex={-1}
                    role="dialog"
                    data-container="#joinPromotionModalMobile"
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button -no-padding-x"
                        role="document"
                    >
                        <div className="modal-content -modal-content">
                            <button
                                type="button"
                                className="close f-1 "
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fas fa-times" />
                            </button>
                            <div className="modal-header -modal-header">
                                <div className="x-modal-mobile-header">
                                    <div className="-header-mobile-container">
                                        <h3 className="x-title-modal mx-auto text-center d-inline-block">
                                            การเข้าร่วมโปรโมชั่น
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body -modal-body">
                                <div className="-outer-history-wrapper">
                                    <div className="x-bill-history-container">
                                        <div
                                            className="wg-container wg-container__wg_bill_history wg--loaded"
                                            data-widget-name="wg_bill_history"
                                            data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                                            data-widget-user-options='{"page":1}'
                                        >
                                            <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "" : "none" }}>
                                                <div className="-no-result-wrapper mt-3">
                                                    <div className="-heading-title">
                                                        <i className="fas fa-times-circle -icon" />
                                                        <div className="-title">ไม่พบข้อมูล</div>
                                                        <div className="-sub-title">คุณยังไม่มีข้อมูลการทำรายการค่ะ</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "none" : "" }}>
                                                <table className="table table-borderless table-striped">
                                                    <tbody>
                                                        {proderedeemlist.map((listitem, i) => (
                                                            <tr key={i} id={i}>
                                                                <td className="-description-body-wrapper">
                                                                    <div className="-title-wrapper">
                                                                        <span className="-title">{listitem.proname}</span>
                                                                        <span style={{ display: (listitem.status === 1) ? "" : "none", margin: "5px" }}>
                                                                            <a href="#0"
                                                                                onClick={(e) => {
                                                                                    this.cancelredeempromotion(e, listitem)
                                                                                }}
                                                                                className="-link-change-password" ><u>ยกเลิก</u></a>
                                                                        </span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 1) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <i className="fas fa-spinner fa-spin"></i>
                                                                        <span className="-state-title">รอดำเนินการ</span>
                                                                        <span className="-state-title -short">รอดำเนินการ  </span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 4) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <img
                                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                            className="-ic"
                                                                            alt=""
                                                                        />
                                                                        <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                                                        <span className="-state-title -short">ยกเลิก (โดยผู้เล่น)</span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 3) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <img
                                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                            className="-ic"
                                                                            alt=""
                                                                        />
                                                                        <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                                                        <span className="-state-title -short">ไม่สำเร็จ (โดยระบบ)</span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 2) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <img
                                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                            className="-ic"
                                                                            alt=""
                                                                        />
                                                                        <span className="-state-title">สำเร็จ</span>
                                                                        <span className="-state-title -short">สำเร็จ</span>
                                                                    </div>
                                                                </td>
                                                                <td className="-transaction-body-wrapper">
                                                                    <div className="-amount -deposit">{listitem.credit.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                                                                    <div className="-datetime">{moment(listitem.datecreate).format('DD/MM/YY') + " - " + moment(listitem.datecreate).format('HH:mm') + " น."} </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default App;
